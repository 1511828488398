import React, {useCallback, useLayoutEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {PageCover} from "../components/global";
import styled from "styled-components";
import {Lightbox} from "yet-another-react-lightbox";
import LightGallery from "lightgallery/react";
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-video.css";
import lgVideo from "lightgallery/plugins/video";
import {Counter, Thumbnails, Zoom} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";
import "yet-another-react-lightbox/plugins/counter.css";
import {FaPlay, FaRegFilePdf} from "react-icons/fa";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import {HiArrowLongLeft, HiArrowLongRight} from "react-icons/hi2";
import {useGetMediaPageQuery} from "../features/api/api-slice";
import TabTitle from "../components/tab-title";
import SEO from "../components/seo";

export default function Media() {
  const { data: mediaPageData } = useGetMediaPageQuery();
  const pageInfo = mediaPageData?.data?.pageInfo;
  const gallery = mediaPageData?.data?.gallery;
  const videos = mediaPageData?.data?.videos;
  const reports = mediaPageData?.data?.reports;
  const info = pageInfo && {
    title: pageInfo.title,
    link: window.location.href,
    description: pageInfo.description,
    image: pageInfo.coverImage,
  };

  return (
    <>
      {pageInfo && (
        <>
          <TabTitle title={pageInfo.title} />
          <SEO info={info} />
        </>
      )}
      <section className={``}>
        {pageInfo && (
          <PageCover title={pageInfo.title} image={pageInfo.coverImage} />
        )}
        {gallery && <Albums galleryInfo={gallery} />}
        {videos && <Videos videosInfo={videos} />}
        {reports && <Reports reportsInfo={reports} />}
      </section>
    </>
  );
}

function Reports({ reportsInfo }) {
  const { t, i18n } = useTranslation("common");
  return (
    <div className={"media-files"} id={"reports-container"}>
      <div className={`py-16`}>
        <div className={"site-width"}>
          <p className={"text-2xl text-center font-bold mb-10"}>
            {t("media.reports")}
          </p>
          <div
            className={
              "grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-7"
            }
          >
            {reportsInfo.map((report, i) => {
              return (
                <a
                  href={report.file}
                  target="_blank" rel="noreferrer"
                  className={"file"}
                  key={`media-files-${i}`}
                  title={report.title}
                >
                  <FaRegFilePdf className={"text-[#d4c7a0] text-[20px]"} />{" "}
                  <span
                    className={`${
                      i18n.language === "en" ? "pl-3" : "pr-3"
                    } relative top-0.5 truncate`}
                  >
                    {report.title}
                  </span>
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

function Videos({ videosInfo }) {
  const { t, i18n } = useTranslation("common");
  return (
    <div className={"bg-shade-3"}>
      <div className={`py-16`}>
        <p className={"text-2xl text-center font-bold mb-10"}>
          {t("media.videos")}
        </p>
        <div className={""}>
          <Swiper
            breakpoints={{
              0: {
                slidesPerView: 1.1,
              },
              540: {
                slidesPerView: 1.5,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
            spaceBetween={30}
            centeredSlides={true}
            loop={true}
            navigation={{
              nextEl: ".videos-s-nav .s-next",
              prevEl: ".videos-s-nav .s-prev",
            }}
            modules={[Navigation]}
          >
            {videosInfo.map((video, i) => {
              return (
                <SwiperSlide key={`media-videos-${i}`}>
                  <YoutubeVideo info={video} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
      <div className={"flex-it-[center-center] pb-16 text-3xl videos-s-nav"}>
        <div className={"w-[100px]"}>
          <div className={"s-next size-[30px] m-auto cursor-pointer"}>
            {i18n.language === "ar" && <HiArrowLongRight className={"m-auto"} />}
            {i18n.language === "en" && <HiArrowLongLeft className={"m-auto"} />}
          </div>
        </div>
        <div className={"w-[100px]"}>
          <div className={"s-prev size-[30px] m-auto cursor-pointer"}>
            {i18n.language === "ar" && <HiArrowLongLeft className={"m-auto"} />}
            {i18n.language === "en" && <HiArrowLongRight className={"m-auto"} />}
          </div>
        </div>
      </div>
    </div>
  );
}
function YoutubeVideo({ info }) {
  const { i18n } = useTranslation("common");

  useLayoutEffect(() => {
    console.warn = () => {};
  });

  const lightGallery = useRef(null);

  const openGallery = useCallback(() => {
    lightGallery.current.openGallery();
  }, []);

  const onInit = useCallback((detail) => {
    if (detail) {
      lightGallery.current = detail.instance;
    }
  }, []);

  const dynamicEl = [
    {
      src: info.video,
      type: "video/mp4",
    },
  ];

  return (
    <LightGallery
      dynamic
      dynamicEl={dynamicEl}
      onInit={onInit}
      plugins={[lgVideo]}
    >
      <CustomVideoSlide
        bgUrl={info.thumbnail}
        className="relative bg-cover bg-no-repeat h-80 cursor-pointer"
        onClick={openGallery}
      >
        <div className="absolute bottom-5 left-5">
          <button className="flex items-center p-3 rounded-full bg-red-600 text-white">
            <FaPlay
              className={`text-white size-[15px] relative ${
                i18n.language === "en" ? "mr-2" : "ml-2"
              }`}
            />{" "}
            <span
              className="text-ellipsis truncate inline-block max-w-[200px]"
              title={info.title}
            >
              {info.title}
            </span>
          </button>
        </div>
      </CustomVideoSlide>
    </LightGallery>
  );
}

const CustomVideoSlide = styled.div`
  background-image: url(${(props) => props.bgUrl});
`;

function Albums({ galleryInfo }) {
  const { t } = useTranslation("common");
  return (
    <div className={`py-16`}>
      <div className={"site-width"}>
        <p className={"text-2xl text-center font-bold mb-10"}>
          {t("media.albums_photos")}
        </p>
        <div
          className={"grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"}
        >
          {galleryInfo.map((album, i) => {
            return <PhotoAlbums info={album} key={`media-album-${i}`} />;
          })}
        </div>
      </div>
    </div>
  );
}
function PhotoAlbums({ info }) {
  const [openAlbum, setOpenAlbum] = useState(false);
  const slides = [];
  for (let i = 0; i < info.images.length; i++) {
    slides.push({ src: info.images[i] });
  }
  return (
    <div className="">
      <div
        className={"album"}
        onClick={() => {
          setOpenAlbum(true);
        }}
      >
        <img src={info.images[0]} alt={""} />
        <p>{info.title}</p>
      </div>
      <Lightbox
        plugins={[Thumbnails, Counter, Zoom]}
        counter={{ style: { top: "unset", bottom: 0 } }}
        open={openAlbum}
        close={() => setOpenAlbum(false)}
        slides={slides}
      />
    </div>
  );
}
