import React from "react";
import {useTranslation} from "react-i18next";
import {BsFillCheckCircleFill} from "react-icons/bs";
import {Link} from "react-router-dom";
import TabTitle from "../components/tab-title";

const Thanks = () => {
  const { t } = useTranslation("common");

  return (
    <>
      <TabTitle title={t("thanks.thanks")} />
      <section className="contain-it-[95-1080] h-[60vh] flex items-center justify-center">
        <div className="text-green-600 flex flex-col gap-8">
          <div className="icon">
            <span className="text-6xl">
              <BsFillCheckCircleFill />
            </span>
          </div>
          <div className="text-4xl flex flex-col gap-4">
            <h1>{t("thanks.thanks")}</h1>
            <p className="text-lg">{t("thanks.in_touch")}</p>
          </div>
          <div className="home">
            <Link reloadDocument={true} className="default-btn" to={"/"}>
              {t("thanks.go_back_home")}
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Thanks;
