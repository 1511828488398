import React from "react";

const ShareLink = ({ link, Icon }) => {
  return (
    <a
      href={link}
      className="bg-gray-900 text-lg text-gray-400 p-3 inline-block self-start"
    >
      <Icon />
    </a>
  );
};

export default ShareLink;
