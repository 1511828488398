import React from "react";

const FostersFilters = ({
  filter,
  fosterFiltersTypesInfo,
  handleActiveFilter,
}) => {
  return (
    <div className="flex gap-2 -mx-2 flex-wrap justify-center">
      {fosterFiltersTypesInfo.map((filterType) => (
        <Filter
          filterType={filterType}
          activeFilter={filter}
          setActiveFilter={handleActiveFilter}
          key={`${filter.id + filterType.id}`}
          handleClick={handleActiveFilter}
        />
      ))}
    </div>
  );
};

const Filter = ({ filterType, activeFilter, handleClick }) => {
  return (
    <div className="">
      <button
        className={`rounded-full border border-gray-300 px-4 whitespace-nowrap py-1 transition-all duration-200 hover:bg-secondary hover:text-white ${
          filterType.id === activeFilter.id
            ? "bg-secondary text-white border-secondary"
            : ""
        }`}
        onClick={() => handleClick(filterType.id)}
      >
        {filterType.text}
      </button>
    </div>
  );
};

export default FostersFilters;
