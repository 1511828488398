import React from "react";

const Hero = ({ heroInfo }) => {
  return (
    <div>
      <div className="image">
        <img
          src={heroInfo.coverImage}
          alt=""
          className="w-full h-96 object-cover"
        />
      </div>
      <div className="text xs:w-[80%] md:w-[60%] mx-auto py-10 text-center border-b border-gray-800">
        <p>{heroInfo.description}</p>
      </div>
    </div>
  );
};

export default Hero;
