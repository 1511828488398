import i18n from "i18next";
import {initReactI18next} from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import common_ar from "./translations/ar/common.json";
import common_en from "./translations/en/common.json";

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "ar",
    lng: window.location.href.split("/")[3] === "en" ? "en" : "ar",
    // debug: true,
    initImmediate: false,
    resources: {
      en: { common: common_en },
      ar: { common: common_ar },
    },
    detection: {
      order: ["path"],
      lookupFromPathIndex: 0,
    },
  });
export default i18n;
