import React from "react";
import {Link, useParams} from "react-router-dom";
import {useGetSearchPageQuery} from "../../features/api/api-slice";
import {useTranslation} from "react-i18next";
import TabTitle from "../../components/tab-title";

const Search = () => {
  const queryParam = useParams().query;
  const { data: searchPageData } = useGetSearchPageQuery(queryParam);
  const searchResults = searchPageData?.data?.items;
  const { t } = useTranslation("common");
  return (
    <>
      <TabTitle title={`${t("search.search_for")} ${queryParam}`} />
      <main className="contain-it-[95-1080]">
        <section className="py-10">
          <div className="header flex flex-col gap-8">
            <h1 className=" text-4xl ">
              {t("search.search_for")} "{queryParam}":
            </h1>
            {}
            <p>
              {searchResults && searchResults.length} {t("search.resultsCount")}
              :
            </p>
          </div>
        </section>
        {searchResults && (
          <section className="pb-10">
            <div className="flex flex-col gap-8">
              {searchResults.length ? (
                searchResults.map((result, index) => (
                  <SearchResult key={index} resultInfo={result} />
                ))
              ) : (
                <NoResult />
              )}
            </div>
          </section>
        )}
      </main>
    </>
  );
};

const SearchResult = ({ resultInfo }) => {
  return (
    <div className="flex flex-col gap-2 text-sm border-b border-gray-900 last:border-none py-4">
      <p className="bg-orange-400 capitalize px-2 py-1 self-start">
        {resultInfo.type}
      </p>
      <Link reloadDocument={true} to={`/${resultInfo.type}/${resultInfo.slug}`}>
        <h2 className="text-lg font-bold">{resultInfo.title}</h2>
      </Link>
      <p className="italic text-[#212529]">{resultInfo.snippet}</p>
    </div>
  );
};

const NoResult = () => {
  const { t } = useTranslation("common");
  return (
    <div className="text-lg  flex flex-col gap-4">
      <span className="font-bold">{t("search.sorry")}</span>
      <span className="text-gray-400">{t("search.no_results")}</span>
    </div>
  );
};

export default Search;
