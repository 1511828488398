import React from "react";

const Hero = ({ heroInfo }) => {
  return (
    <div>
      <div className="bg-primary pt-10">
        <div className="contain-it-[95-1080]">
          <div className="flex justify-center flex-col gap-y-5 text-center -mb-16">
            <div className="title text-white text-2xl">
              <h1>{heroInfo.title}</h1>
            </div>
            <div className="image">
              <img src={heroInfo.coverImage} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="pb-16 bg-white"></div>
      <div className="xs:w-[80%] sm:w-[60%] mx-auto text-center py-10 text-gray-200">
        <p>{heroInfo.description}</p>
      </div>
    </div>
  );
};

export default Hero;
