import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PageCover } from "../components/global";
import { HiOutlineMapPin } from "react-icons/hi2";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import {
  useContactMutation,
  useGetContactPageQuery,
  useGetGlobalsQuery,
} from "../features/api/api-slice";
import TabTitle from "../components/tab-title";
import Map from "../components/map";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { post } from "../assets/js/helpers";

export default function Contact() {
    const { t } = useTranslation("common");
    const { data: contactPageData } = useGetContactPageQuery();
    const { data: globalData } = useGetGlobalsQuery();
    const pageInfo = contactPageData?.data?.pageInfo;
    const faq = contactPageData?.data?.faq;
    const email = globalData?.data?.email;
    const address = globalData?.data?.address;
    const location = globalData?.data?.map;

    return (
        <>
            {pageInfo && <TabTitle title={pageInfo.title} />}
            <section className={``}>
                {pageInfo && (
                    <PageCover title={pageInfo.title} image={pageInfo.coverImage} />
                )}
                <div className={"site-width"}>
                    <div className={"grid xs:grid-row-2 lg:grid-cols-12 gap-8 py-16"}>
                        <div className={"xs:row-span-1 lg:col-span-9"}>
                            <ContactForm />
                        </div>
                        <div className={"xs:xs:row-span-1 lg:col-span-3"}>
                            <HiOutlineMapPin className={`text-secondary text-[30px] mb-2`} />
                            <p className={`font-bold mb-2`}>{address}</p>
                            <a className={`font-bold`} href={`mailto:${email}`}>
                                {email}
                            </a>
                        </div>
                    </div>
                    <div className={"map-container"}>
                        <div className={``}>
                            <p className={"text-2xl text-center font-bold mb-10"}>
                                {t("contact.location")}
                            </p>
                            <div className="w-full h-96">
                                {location && <Map location={location} />}
                            </div>
                        </div>
                    </div>
                    <div>{faq && <FAQ faqInfo={faq} />}</div>
                </div>
            </section>
        </>
    );
}

function FAQ({ faqInfo }) {
    const { t } = useTranslation("common");

    return (
        <div className={"faqs"}>
            <div className={`py-10 flex flex-col gap-4`}>
                <p className={"text-2xl font-bold"}>{t("contact.contact_faqs")}</p>
                <div className={""}>
                    {faqInfo.map((faq, i) => (
                        <Question info={faq} key={`contact-faq-${i}`} />
                    ))}
                </div>
            </div>
        </div>
    );
}
function Question({ info }) {
    const { t, i18n } = useTranslation("common");
    const [open, setOpen] = useState(false);

    return (
        <div className={"faq"}>
            <button
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <span>{info.title}</span>
                {!open && <FaPlusCircle />}
                {open && <FaMinusCircle />}
            </button>
            {open && <div className={"answer"}>{info.description}</div>}
        </div>
    );
}

function ContactForm() {
    const { t } = useTranslation("common");
    const [contact, result] = useContactMutation();

    const initialValues = {
        name: "",
        email: "",
        phoneNumber: "",
        message: "",
    };

    const handleSubmit = async (values) => {
        await contact(values);
        alert(
            result.isSuccess ? t("contact.form_success") : t("contact.form_failed")
        );
    };

    return (
        <div className={"form contact-form"}>
            <Formik initialValues={initialValues} onSubmit={handleSubmit}>
                <Form>
                    <div className={"grid grid-cols-8 gap-8"}>
                        <div className={"col-span-4"}>
                            <div className={"input-container"}>
                                <Field
                                    type={"text"}
                                    required={true}
                                    name="name"
                                    placeholder={t("contact.form_name")}
                                />
                            </div>
                            <div className={"input-container"}>
                                <Field
                                    type={"email"}
                                    required={true}
                                    name="email"
                                    placeholder={t("contact.form_email")}
                                />
                            </div>
                            <div className={"input-container"}>
                                <Field
                                    type={"number"}
                                    required={true}
                                    name="phoneNumber"
                                    placeholder={t("contact.form_phone")}
                                />
                            </div>
                        </div>
                        <div className={"col-span-4"}>
                            <div className={"input-container w-full h-full"}>
                                <Field
                                    required={true}
                                    name="message"
                                    placeholder={t("contact.form_message")}
                                    as="textarea"
                                />
                            </div>
                        </div>
                    </div>
                    <button type="submit">{t("contact.form_submit")}</button>
                </Form>
            </Formik>
        </div>
    );
}
