import React from "react";
import ContactHeading from "./contact-heading";
import {FaFacebookF, FaTwitter, FaWhatsapp} from "react-icons/fa";
import ShareLink from "./share-link";
import {getCurrentUrl} from "./global";
import {useTranslation} from "react-i18next";

const CallShare = ({ shareInfo, title }) => {
  const currentUrl = getCurrentUrl();
  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${currentUrl}&quote=${title}`;
  const twitterLink = `https://twitter.com/intent/tweet?text=${title}&url=${currentUrl}`;
  const whatsappLink = `https://wa.me/?text=${title}%5Cn%20${currentUrl}`;

  const { t } = useTranslation("common");
  return (
    <>
      <div className="call flex flex-col gap-2">
        <ContactHeading>{t("share.call_us")}:</ContactHeading>
        <a href={`tel:${shareInfo.phone}`} className="underline">
          {shareInfo.phone}
        </a>
      </div>
      <div className="email-us flex flex-col gap-2 pt-10">
        <ContactHeading>{t("share.email_us")}:</ContactHeading>
        <a href={`mailto:${shareInfo.email}`} className="underline">
          {shareInfo.email}
        </a>
      </div>
      <div className="share pt-10 flex flex-col gap-4">
        <ContactHeading>{t("share.share")}</ContactHeading>
        <div className="flex flex-col gap-3 grow-0">
          <ShareLink Icon={FaFacebookF} link={facebookLink} />
          <ShareLink Icon={FaTwitter} link={twitterLink} />
          <ShareLink Icon={FaWhatsapp} link={whatsappLink} />
        </div>
      </div>
    </>
  );
};

export default CallShare;
