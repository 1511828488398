import React from "react";
import {getCurrentUrl} from "./global";
import {FaFacebookF, FaTwitter, FaWhatsapp} from "react-icons/fa";

const ShareLinks = ({ title }) => {
  const link = getCurrentUrl();
  return (
    <div className={"flex gap-3 text-gray-650"}>
      <a
        href={`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${title}`}
        target="_blank" rel="noreferrer"
        className="hover:text-gray-400 transition-all duration-200"
      >
        <FaFacebookF />
      </a>
      <a
        href={`https://twitter.com/intent/tweet?text=${title}&url=${link}`}
        target="_blank" rel="noreferrer"
        className="hover:text-gray-400 transition-all duration-200"
      >
        <FaTwitter />
      </a>
      <a
        href={`https://wa.me/?text=${title}%5Cn%20${link}`}
        target="_blank" rel="noreferrer"
        className="hover:text-gray-400 transition-all duration-200"
      >
        <FaWhatsapp />
      </a>
    </div>
  );
};

export default ShareLinks;
