import {useTranslation} from "react-i18next";
import React from "react";
import {Link} from "react-router-dom";

export function PageCover({ title, image }) {

  return (
    <div
      className={"page-cover"}
      style={{ backgroundImage: `url('${image}')` }}
    >
      <div className={"site-width"}>
        <p className={"t"}>{title}</p>
      </div>
    </div>
  );
}

export const getCurrentUrl = () => {
  return window.location.href;
};

export const getUrlParamValue = (url, param) => {
  const params = new URLSearchParams(url.split("?")[1]);
  return params.get(param);
};

export function SideTab({ title, content }) {
  return (
    <div className={"side-tab mb-6 last:mb-0"}>
      <p className={"bg-secondary text-white text-center font-bold p-3"}>
        {title}
      </p>
      <p className={"bg-gray-950 text-center font-bold p-3"}>{content}</p>
    </div>
  );
}

export function ProgramContainer({ program }) {
  return (
    <Link reloadDocument={true} to={program.link} className={"program"}>
      <img src={program.image} alt={""} />
      <p className={""}>{program.title}</p>
    </Link>
  );
}
