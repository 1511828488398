import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import i18n from "../../i18n";
import { objectToUrlEncodedForm } from "../../assets/js/helpers";

const baseUrl = process.env.REACT_APP_BASE_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const baseQuery = fetchBaseQuery({
  baseUrl,
  prepareHeaders: (headers, api) => {
    headers.set("content-type", "application/json");
    headers.set("Content-Type", "application/x-www-form-urlencoded");
    headers.set("Accept", "application/json");
    headers.set("X-APIKEY", API_KEY);
    headers.set("X-LANG", i18n.language);
    return headers;
  },
});

// Get Queries endpoints
const getEndpoint = "/get";

const homeQuery = () => ({
  url: `${getEndpoint}/home`,
});

const aboutQuery = () => ({
  url: `${getEndpoint}/about`,
});

const kafalaQuery = () => ({
  url: `${getEndpoint}/kafalas`,
});

const fosterQuery = (fosterSlug) => ({
  url: `${getEndpoint}/kafala?slug=${fosterSlug}`,
});

const newsQuery = () => ({
  url: `${getEndpoint}/news`,
});

const articleQuery = (articleSlug) => ({
  url: `${getEndpoint}/article?slug=${articleSlug}`,
});

const programsQuery = () => ({
  url: `${getEndpoint}/programs`,
});

const programQuery = (slug) => ({
  url: `${getEndpoint}/program?slug=${slug}`,
});

const contactQuery = () => ({
  url: `${getEndpoint}/contact`,
});

const mediaQuery = () => ({
  url: `${getEndpoint}/media`,
});

const globalsQuery = () => ({
  url: `${getEndpoint}/globals`,
});

const searchQuery = (query) => ({
  url: `${getEndpoint}/search?q=${query}`,
});

// Post Queries endpoints
const postEndpoint = "/post";

const subscribeNewsletterMutation = (body) => ({
  url: `${postEndpoint}/newsletter`,
  method: "POST",
  body,
});

const contactMutation = (body) => ({
  url: `${postEndpoint}/contact`,
  method: "POST",
  body,
});

const sponsorsMutation = (body) => {
  const formattedBody = objectToUrlEncodedForm(body);
  return {
    url: `${postEndpoint}/kafala`,
    method: "POST",
    body: formattedBody,
  };
};

const donateMutation = (body) => {
  // delete body.type;
  const formattedBody = objectToUrlEncodedForm(body);
  return {
    url: `${postEndpoint}/donate`,
    method: "POST",
    body: formattedBody,
  };
};

// Api Slice
const apiSlice = createApi({
  reducerPath: "api",
  baseQuery,
  endpoints: (builder) => ({
    getHomePage: builder.query({
      query: homeQuery,
    }),
    getAboutPage: builder.query({
      query: aboutQuery,
    }),
    getKafalaPage: builder.query({
      query: kafalaQuery,
    }),
    getFosterPage: builder.query({
      query: fosterQuery,
    }),
    getNewsPage: builder.query({
      query: newsQuery,
    }),
    getArticlePage: builder.query({
      query: articleQuery,
    }),
    getProgramsPage: builder.query({
      query: programsQuery,
    }),
    getProgramPage: builder.query({
      query: programQuery,
    }),
    getContactPage: builder.query({
      query: contactQuery,
    }),
    getMediaPage: builder.query({
      query: mediaQuery,
    }),
    getGlobals: builder.query({
      query: globalsQuery,
    }),
    getSearchPage: builder.query({
      query: searchQuery,
    }),
    subscribeNewsletter: builder.mutation({
      query: subscribeNewsletterMutation,
    }),
    contact: builder.mutation({
      query: contactMutation,
    }),
    sponsor: builder.mutation({
      query: sponsorsMutation,
    }),
    donate: builder.mutation({
      query: donateMutation,
    }),
  }),
});

export { apiSlice };
export const {
  useGetHomePageQuery,
  useGetAboutPageQuery,
  useGetKafalaPageQuery,
  useGetFosterPageQuery,
  useGetNewsPageQuery,
  useGetArticlePageQuery,
  useGetProgramsPageQuery,
  useGetProgramPageQuery,
  useGetContactPageQuery,
  useGetMediaPageQuery,
  useGetGlobalsQuery,
  useGetSearchPageQuery,
  useSubscribeNewsletterMutation,
  useContactMutation,
  useSponsorMutation,
  useDonateMutation,
} = apiSlice;
