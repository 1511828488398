
import Home from "./pages/home";
import About from "./pages/about/about";
import Contact from "./pages/contact";
import NewsIndex from "./pages/news";
import ProgramsIndex from "./pages/programs";
import ProgramsEntry from "./pages/programs/entry";
import ReliefIndex from "./pages/kafala";
import Kafala from "./pages/kafala";
import ReliefEntry from "./pages/kafala/entry";
import ReliefCategory from "./pages/kafala/category";
import Layout from "./layout/layout";
import Error404 from "./pages/error-404";
import {HelmetProvider} from "react-helmet-async";
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import {useTranslation} from "react-i18next";
import Article from "./pages/article/article";
import {Provider} from "react-redux";
import store from "./app/store";
import Media from "./pages/media";
import Fostering from "./pages/fostering/fostering";
import Donate from "./pages/donate/donate";
import Search from "./pages/search/search";
import HeadOverride from "./components/head-override";
import icoImage from "./assets/images/favicon-32x32.png";
import Thanks from "./pages/thanks";
import "./assets/App.css";

function App() {
  const { i18n } = useTranslation();
  const routesList = [
    { path: "/", element: <Home /> },
    { path: "/about", element: <About /> },
    { path: "/contact", element: <Contact /> },
    { path: "/media", element: <Media /> },
    { path: "/news", element: <NewsIndex /> },
    { path: "/news/:slug", element: <Article /> },
    { path: "/programs", element: <ProgramsIndex /> },
    { path: "/programs/:slug", element: <ProgramsEntry /> },
    { path: "/relief", element: <ReliefIndex /> },
    { path: "/relief/category/:slug", element: <ReliefCategory /> },
    { path: "/relief/:slug", element: <ReliefEntry /> },
    { path: "/kafala", element: <Kafala /> },
    { path: "/kafala/:fosterId", element: <Fostering /> },
    { path: "/donate", element: <Donate /> },
    { path: "/search/:query", element: <Search /> },
    { path: "/thanks", element: <Thanks /> },
  ];
  const routes = createBrowserRouter(
    [
      {
        path: "/",
        element: <Layout />,
        errorElement: <Error404 />,
        children: routesList,
      },
    ],
    {
      basename: i18n.language === "en" ? `/en` : `/`,
    }
  );

  return (
    <Provider store={store}>
      <HelmetProvider>
        <HeadOverride>
          <link rel="icon" type="image/png" sizes="32x32" href={icoImage} />
        </HeadOverride>
        <RouterProvider router={routes} />
      </HelmetProvider>
    </Provider>
  );
}

export default App;
