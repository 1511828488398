import {configureStore} from "@reduxjs/toolkit";
import {apiSlice} from "../features/api/api-slice";

const store = configureStore({
  reducer: {
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleWare) =>
    getDefaultMiddleWare().concat(apiSlice.middleware),
});


export default store;
