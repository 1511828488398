import React from "react";
import {Helmet} from "react-helmet-async";
import {useTranslation} from "react-i18next";

export default function SEO({ info }) {
  const { t } = useTranslation("common");
  return (
    <Helmet>
      <title>
        {t("app.site_name")} | {info.title}
      </title>
      <link rel="canonical" href={info.link} />

      <meta name="description" content={info.description} />

      <meta itemProp="name" content={info.title} />
      <meta itemProp="description" content={info.description} />
      <meta itemProp="image" content={info.image} />

      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={info.title} />
      <meta property="og:url" content={info.link} />
      <meta property="og:title" content={info.title} />
      <meta property="og:description" content={info.description} />
      <meta property="og:image" itemProp="image" content={info.image} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:url" content={info.link} />
      <meta name="twitter:title" content={info.title} />
      <meta name="twitter:description" content={info.description} />
      <meta name="twitter:image" content={info.image} />
    </Helmet>
  );
}
