const toggleScrolling = (
  value = false,
  element = document.getElementsByTagName("html")[0]
) => {
  if (value) {
    element.classList.add("overflow-hidden");
  } else {
    element.classList.remove("overflow-hidden");
  }
};

const objectToUrlEncodedForm = (object) => {
  return Object.entries(object)
    .map((entry) => `${entry.join("=")}`)
    .join("&");
};

export { toggleScrolling, objectToUrlEncodedForm };
