import React from "react";
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import styled from "styled-components";

const CustomTabs = ({ tabsInfo }) => {
  return (
    <CustomStyledTabs>
      <Tabs>
        <TabList className="flex gap-[2px] pb-10 justify-center flex-wrap">
          {tabsInfo.map((tab) => (
            <Tab key={tab.id}>{tab.tab}</Tab>
          ))}
        </TabList>
        {tabsInfo.map((tab) => (
          <TabPanel key={tab.id} className={"xs:w-[80%] md:w-[60%] mx-auto"}>
            <div dangerouslySetInnerHTML={{ __html: tab.TabPanel }}></div>
          </TabPanel>
        ))}
      </Tabs>
    </CustomStyledTabs>
  );
};

const CustomStyledTabs = styled.div`
  .react-tabs__tab {
    background-color: #921831;
    cursor: pointer;
    padding: 8px 20px;
    color: white;
  }

  .react-tabs__tab--selected {
    background-color: white;
    color: black;
  }
`;

export default CustomTabs;
