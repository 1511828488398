import React from "react";
import {Link} from "react-router-dom";
import NewsCard from "../../components/news-card";
import {useTranslation} from "react-i18next";

const NewsEvents = ({ featuredNewsInfo }) => {
  const [t] = useTranslation("common");
  return (
    <div className="xs:w-[80%] md:w-[60%] mx-auto">
      <div className="header flex justify-between pb-4">
        <div className="title text-lg">
          <span>{t("news_cards.heading")}</span>
        </div>
        <div>
          <Link reloadDocument={true}
            to=""
            className="border-b-2 text-sm transition-all duration-200 hover:text-secondary"
          >
            {t("news_cards.show_all")}
          </Link>
        </div>
      </div>
      <div className="news-events flex gap-5 xs:flex-col lg:flex-row">
        {featuredNewsInfo.slice(0, 2).map((news, index) => (
          <NewsCard key={index} singleNewsInfo={news} />
        ))}
      </div>
    </div>
  );
};

export default NewsEvents;
