import React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useGetFosterPageQuery, useSponsorMutation,} from "../../features/api/api-slice";
import styled from "styled-components";
import {useTranslation} from "react-i18next";
import CallShare from "../../components/call-share";
import TabTitle from "../../components/tab-title";
import {Field, Form, Formik} from "formik";
import SEO from "../../components/seo";

const Fostering = () => {
  const fosterId = useParams().fosterId;
  const { data: fosterPageData } = useGetFosterPageQuery(fosterId);
  const foster = fosterPageData?.data;
  const info = fosterPageData?.data?.info;
  const seoInfo = info && {
    title: info.title,
    link: window.location.location,
    description: info.description,
    image: info.coverImage,
  };

  return (
    <>
      {foster && (
        <>
          <TabTitle title={foster.title} />
          <SEO info={seoInfo} />
          <section className="bg-secondary flex items-center h-96 pb-4">
            <div className="contain-it-[95-1080]">
              <div className="main-info">
                <div className="image overflow-hidden w-28 h-28 rounded-full">
                  <img
                    src={foster.coverImage}
                    alt=""
                    className="h-full w-full object-cover"
                  />
                </div>
                <div className="name text-3xl text-white pt-4">
                  <h2>
                    {foster.title} - {foster.city}
                  </h2>
                </div>
              </div>
            </div>
          </section>
          <section className="other-details contain-it-[95-1080] flex xs:flex-col md:flex-row pb-7">
            <div className="more-info xs:w-full md:w-2/3 border border-slate-300 -mt-16 p-10 pb-0 bg-white">
              <div className="info-table border-b border-slate-300 pb-10">
                <CustomTable>
                  <div
                    dangerouslySetInnerHTML={{ __html: foster.content }}
                  ></div>
                </CustomTable>
              </div>
              <div className="fostering-form py-10">
                <SponsorForm fosterId={foster.id} />
              </div>
            </div>
            <div className="call-share xs:w-full md:w-1/3 p-10">
              {info && <CallShare shareInfo={info} />}
            </div>
          </section>
        </>
      )}

      <section className="other-details contain-it-[95-1080]  flex xs:flex-col md:flex-row py-10">
        {info && (
          <KafalaParagraph heading={info.title} paragraph={info.description} />
        )}
      </section>
    </>
  );
};

const CustomTable = styled.div`
  table {
    border-collapse: collapse;
    width: 100%;
  }

  td {
    border: 1px solid #cbd5e1;
    padding: 12px;
  }
`;

const CustomInput = ({
  type,
  value,
  name,
  placeholder,
  text,
  suffix,
  ...props
}) => {
  return (
    <label htmlFor={name} className="flex items-center gap-5 gap-y-1 flex-wrap">
      <span className="xs:w-full md:w-1/12 lg:w-2/12">{text}</span>
      <Field
        type={type}
        name={name}
        id={name}
        placeholder={placeholder}
        className="border flex-auto border-slate-300 px-3 py-2 outline-none focus:bg-white focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]"
        {...props}
      />
      {suffix && <span className="">{suffix}</span>}
    </label>
  );
};

const CustomTextArea = ({ text, placeholder, name }) => {
  return (
    <label htmlFor={name} className="flex items-start gap-5 gap-y-1 flex-wrap">
      <span className="xs:w-full md:w-1/12 lg:w-2/12">{text}</span>
      <Field
        as="textarea"
        rows={5}
        id={name}
        name={name}
        placeholder={placeholder}
        className="border flex-auto border-slate-300 px-3 py-2 outline-none focus:bg-white focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]"
      />
    </label>
  );
};

const KafalaParagraph = ({ heading, paragraph }) => {
  return (
    <div className="bg-[#f8f9fa] p-10">
      <h3 className="text-3xl pb-7">{heading}</h3>
      <p className="text-base text-[#212529]">{paragraph}</p>
    </div>
  );
};

const SponsorForm = ({ fosterId }) => {
  const { t } = useTranslation("common");
  const [sponsor] = useSponsorMutation();
  const navigate = useNavigate();
  const initialValues = {
    name: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
    amount: 15000,
    period: 1,
  };

  const handleSubmit = async (values) => {
    const body = {
      id: fosterId,
      ...values,
    };
    await sponsor(body);
    navigate("/thanks");
  };

  return (
    <>
      <div className="heading text-2xl py-4">
        <h4>{t("common_form.data_info")}</h4>
      </div>
      <div className="form-wrapper">
        <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          <Form className="flex flex-col gap-4">
            <CustomInput
              type={"number"}
              name={"period"}
              text={t("common_form.period")}
              suffix={t("common_form.per_month")}
              required={true}
            />
            <CustomInput
              type={"number"}
              name={"amount"}
              text={t("common_form.price")}
              suffix={t("common_form.currency")}
              required={true}
            />
            <div className="border-b border-slate-300"></div>
            <CustomInput
              type={"text"}
              name={"name"}
              text={t("common_form.name")}
              required={true}
            />
            <CustomInput
              type={"phone"}
              name={"phoneNumber"}
              text={t("common_form.phone_number")}
              required={true}
            />
            <CustomInput
              type={"email"}
              name={"email"}
              text={t("common_form.email")}
              required={true}
            />
            <CustomInput
              type={"text"}
              name={"address"}
              text={t("common_form.address")}
              required={true}
            />
            <CustomTextArea
              text={t("common_form.questions")}
              name="message"
              placeholder={t("common_form.questions")}
            />
            <div className="self-end">
              <button type="submit" className="default-btn">
                {t("common_form.send")}
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </>
  );
};

export default Fostering;
