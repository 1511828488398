import React from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, Pagination} from "swiper/modules";
import {Link} from "react-router-dom";

import styled from "styled-components";
import {useTranslation} from "react-i18next";

const PrimarySlider = ({ slidesInfo }) => {
  const [t] = useTranslation("common");
  return (
      <div>
        <div className="heading">
          <h2 className="text-lg text-center">{t("home.basamat_projects")}</h2>
        </div>
        <CustomSlide className="slides py-5">
          <Swiper
              modules={[Pagination, Autoplay]}
              pagination={{
                clickable: true,
              }}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              spaceBetween={25}
              breakpoints={{
                0: {
                  slidesPerView: 1,
                },
                450: {
                  slidesPerView: 2,
                },
                768: {
                  slidesPerView: 3,
                },
                920: {
                  slidesPerView: 5,
                },
              }}
          >
            {slidesInfo.map((slide, index) => {
              return (
                  <SwiperSlide key={index}>
                    <SingleSlide index={index} slideInfo={slide} />
                  </SwiperSlide>
              );
            })}
          </Swiper>
        </CustomSlide>
      </div>
  );
};

const CustomSlide = styled.div`
  .swiper-pagination {
    position: initial;
    padding-top: 30px;
  }

  .swiper-pagination span {
    background: black;
  }
`;

const SingleSlide = ({ slideInfo, index }) => {
  return (
      <Link reloadDocument={true}
            to={`/programs/${slideInfo.slug}`}
            className={`group block transition-all duration-500 ${
                index % 2 === 0 ? "hover:bg-secondary" : "hover:bg-primary"
            }  hover:text-white`}
      >
        <div className="image overflow-hidden relative after:transition-all after:duration-500 after:opacity-0 group-hover:after:opacity-100 after:content-[''] after:h-full after:w-full after:bg-gradient-to-t group-hover:after:from-black after:absolute after:top-0 after:left-0">
          <CustomImage
              src={slideInfo.image}
              alt=""
              className="block hover:text-white"
          />
        </div>
        <div className="text px-2 py-3">
          <p className="text-center text-lg font-bold">{slideInfo.title}</p>
        </div>
      </Link>
  );
};

const CustomImage = styled.img`
  -webkit-transform: rotateX(-2deg);
  -webkit-transform-origin: left;
  transition: all 3s;
  &:hover {
    transform: rotateX(0deg);
  }
`;

export default PrimarySlider;
