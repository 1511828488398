import React from "react";
import heroBg from "../../assets/images/rep-bg.jpg";
import styled from "styled-components";
import accountsBankImage from "../../assets/images/dshape1.png";
import CallShare from "../../components/call-share";
import {useTranslation} from "react-i18next";
import {useDonateMutation, useGetGlobalsQuery,} from "../../features/api/api-slice";
import TabTitle from "../../components/tab-title";
import {Field, Form, Formik} from "formik";
import {useNavigate} from "react-router-dom";

const Donate = () => {
  const { t } = useTranslation("common");

  const { data: globalData } = useGetGlobalsQuery();
  const email = globalData?.data?.donateEmail;
  const phone = globalData?.data?.phone;
  const donateInfo = { phone: phone, email: email };
  const donateDetails = globalData?.data?.donateDetails;

  return (
    <>
      <TabTitle title={t("donate.donate_now")} />
      <HeroSection className="flex items-center h-96 pb-4">
        <div className="contain-it-[95-1080] text-white text-center">
          <h1 className="text-4xl py-3 drop-shadow-md">
            {t("donate.donate_now")}
          </h1>
          <p className="text-base drop-shadow-md">
            {t("donate.donate_paragraph")}
          </p>
        </div>
      </HeroSection>
      <section className="other-details contain-it-[95-1080] flex xs:flex-col md:flex-row pb-7">
        <div className="more-info xs:w-full md:w-2/3 -mt-16 p-10 bg-[#eee]">
          <DonateForm />
        </div>
        <div className="call-share xs:w-full md:w-1/3 p-10">
          <CallShare shareInfo={donateInfo} title={t("donate.donate_now")} />
        </div>
      </section>
      <section className="other-details contain-it-[95-1080] mb-10">
        {donateDetails && <AccountBanks banksInfo={donateDetails} />}
      </section>
    </>
  );
};

const DonateForm = () => {
  const { t } = useTranslation("common");
  const [donate] = useDonateMutation();
  const donation_options = [
    { value: "", text: t("donate.form_donate_type") },
    { value: "donation1", text: t("donate.options.once") },
    { value: "donation2", text: t("donate.options.every_month") },
  ];

  const navigate = useNavigate();

  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    message: "",
    type: "",
    amount: "",
  };

  const handleSubmit = async (values) => {
    await donate(values);
    navigate("/thanks");
  };

  return (
    <div className="fostering-form">
      <div className="heading text-2xl pb-10 font-bold">
        <h4>{t("donate.donate_info")}</h4>
      </div>
      <Formik
        className="form-wrapper"
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form className="grid xs:grid-cols-1 md:grid-cols-2 gap-4">
          <div className="xs:col-span-2 md:col-span-1">
            <CustomInput
              type={"text"}
              name={"firstName"}
              placeholder={t("donate.form_name")}
              required
            />
          </div>
          <div className="xs:col-span-2 md:col-span-1">
            <CustomInput
              type={"text"}
              name={"lastName"}
              placeholder={t("donate.form_surname")}
              required
            />
          </div>
          <div className="xs:col-span-2 md:col-span-1">
            <CustomInput
              type={"email"}
              name={"email"}
              placeholder={t("donate.form_email")}
              required
            />
          </div>
          <div className="xs:col-span-2 md:col-span-1">
            <CustomInput
              type={"number"}
              name={"phoneNumber"}
              placeholder={t("donate.form_phone")}
              required
            />
          </div>
          <div className="col-span-2">
            <CustomInput
              type={"text"}
              name={"address"}
              placeholder={t("donate.form_address")}
              required
            />
          </div>
          <div className="xs:col-span-2 md:col-span-1">
            <CustomSelect
              id={"donation_type"}
              name={"type"}
              text={t("donate.form_donate_type")}
              options={donation_options}
            />
          </div>
          <div className="xs:col-span-2 md:col-span-1">
            <CustomInput
              type={"number"}
              name={"amount"}
              placeholder={t("donate.form_value")}
              required
            />
          </div>
          <div className="col-span-2">
            <CustomTextArea
              name="message"
              placeholder={t("donate.form_question")}
              required
            />
          </div>
          <div className="col-span-1">
            <button type="submit" className="secondary-btn">
              {t("donate.form_send")}
            </button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

const AccountBanks = ({ banksInfo }) => {
  const { t } = useTranslation("common");
  return (
    <AccountBanksBg className="xs:w-full md:w-2/3 bg-[#f7f7f7] bg-no-repeat bg-top bg-contain py-14 px-6">
      <div className="header text-3xl">
        <h2>{t("donate.bank_accounts")}</h2>
      </div>
      <div className="accounts-list py-4 font-bold italic">
        <div dangerouslySetInnerHTML={{ __html: banksInfo }}></div>
      </div>
    </AccountBanksBg>
  );
};

const CustomSelect = ({ id, name, options }) => {
  return (
    <Field
      as="select"
      name={name}
      id={id}
      className="h-full w-full px-3 py-2 outline-none focus:bg-white focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]"
    >
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.text}
        </option>
      ))}
    </Field>
  );
};

const AccountBanksBg = styled.div`
  background-image: url(${accountsBankImage});
`;
const HeroSection = styled.section`
  background-image: url(${heroBg});
`;

const CustomInput = ({ type, value, name, placeholder, ...props }) => {
  return (
    <label
      htmlFor={name}
      className="flex items-center gap-5 gap-y-1 flex-wrap w-full"
    >
      <Field
        type={type}
        name={name}
        id={name}
        value={value}
        placeholder={placeholder}
        className="flex-auto px-3 py-2 outline-none focus:bg-white focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]"
        {...props}
      />
    </label>
  );
};

const CustomTextArea = ({ placeholder, name, ...props }) => {
  return (
    <label htmlFor={name} className="flex items-start gap-5 gap-y-1 flex-wrap">
      <Field
        as="textarea"
        rows={5}
        name={name}
        id={name}
        placeholder={placeholder}
        {...props}
        className="flex-auto px-3 py-2 outline-none focus:bg-white focus:border-[#80bdff] focus:shadow-[0_0_0_0.2rem_rgb(0_123_255_/_25%)]"
      />
    </label>
  );
};

export default Donate;
