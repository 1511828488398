import React, {useState} from "react";
import FostersFilters from "./fosters-filters";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {useTranslation} from "react-i18next";

const FostersList = ({ fostersInfo, fostersFiltersTypesInfo }) => {
  const [filter, setFilter] = useState(fostersFiltersTypesInfo[0]);
  const [filteredFosters, setFilteredFosters] = useState([...fostersInfo]);

  const handleActiveFilter = (activeFilterId) => {
    setFilter(
      fostersFiltersTypesInfo.find((filter) => filter.id === activeFilterId)
    );

    setFilteredFosters(
      activeFilterId !== 0
        ? [...fostersInfo.filter((foster) => foster.type.id === activeFilterId)]
        : [...fostersInfo]
    );
  };

  return (
    <div className="xs:w-[80%] md:w-[60%] mx-auto">
      <div className="">
        <FostersFilters
          filter={filter}
          setFilter={setFilter}
          fosterFiltersTypesInfo={fostersFiltersTypesInfo}
          handleActiveFilter={handleActiveFilter}
        />
      </div>
      <div className="grid xs:grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-3 pt-5">
        {filteredFosters.map((foster, index) => (
          <Foster key={index} fosterInfo={foster} />
        ))}
      </div>
    </div>
  );
};

const Foster = ({ fosterInfo }) => {
  const { t } = useTranslation("common");
  return (
    <HoverFoster
      to={fosterInfo.slug}
      className="group flex flex-col justify-between min-h-[300px] items-center border border-gray-650 transition-all duration-200 hover:bg-secondary hover:text-white"
    >
      <div className="image flex justify-center items-center w-36 h-36 overflow-hidden p-3">
        <img
          src={fosterInfo.coverImage}
          alt=""
          className="w-full h-full object-cover rounded-full"
        />
      </div>
      <div className="info text-center px-4">
        <h3 className="w-full text-center text-xl">{fosterInfo.title}</h3>
        <p className="text-sm text-gray-400 py-2 group-hover:text-white">
          <span>{t("fostering.age")} </span>
          <span>{fosterInfo.age} </span>
          <span className="text-red-600 group-hover:text-white">
            - {fosterInfo.city}
          </span>
        </p>
      </div>
      <div className="foster-action pt-2 w-full">
        <span
          to={fosterInfo.slug}
          className="inline-block bg-primary text-white py-1 w-full text-center"
        >
          {t("fostering.foster_me")}
        </span>
      </div>
    </HoverFoster>
  );
};

const HoverFoster = styled(Link)`
  &:hover {
    transform: translateY(-5px);
  }
`;

export default FostersList;
