import React from "react";
import {CgFacebook, CgInstagram} from "react-icons/cg";
import {FaWhatsapp} from "react-icons/fa";
import {BsTwitter} from "react-icons/bs";
import {AiFillYoutube} from "react-icons/ai";
import {useTranslation} from "react-i18next";
import {useGetGlobalsQuery} from "../features/api/api-slice";

const Share = () => {
  const { data: global } = useGetGlobalsQuery();
  const social = global?.data?.social;
  const { i18n } = useTranslation("common");
  return (
    <>
      {social && (
          <div className="flex flex-col">
            <a href={social.facebook}
               className={`fb inline-flex justify-end w-14 text-2xl p-2 bg-[#3b5999] text-white transition-all duration-300 hover:translate-x-0 hover:bg-[#3b5999]/80 ${
                   i18n.language === "ar" ? "translate-x-4" : "-translate-x-4"
               }`}><CgFacebook/></a>
            <a href={social.instagram}
               className={`fb inline-flex justify-end w-14 text-2xl p-2 bg-gradient-to-r from-purple-600 via-red-600 to-yellow-400 text-white transition-all duration-300 hover:translate-x-0 hover:bg-[#3b5999]/80 ${
                   i18n.language === "ar" ? "translate-x-4" : "-translate-x-4"
               }`}><CgInstagram/></a>
            <a href={social.twitter}
               className={`twitter  inline-flex justify-end w-14 text-2xl p-2 bg-[#55acee] text-white transition-all duration-300 hover:translate-x-0 hover:bg-[#55acee]/80 ${
                   i18n.language === "ar" ? "translate-x-4" : "-translate-x-4"
               }`}><BsTwitter/></a>
            <a href={social.youtube}
               className={`yt inline-flex justify-end w-14 text-2xl p-2 bg-[#ff0000] text-white transition-all duration-300 hover:translate-x-0 hover:bg-[#ff0000]/80 ${
                   i18n.language === "ar" ? "translate-x-4" : "-translate-x-4"
               }`}><AiFillYoutube/></a>
            <a href={social.whatsapp}
               className={`wat inline-flex justify-end w-14 text-2xl p-2 bg-[#4cc359] text-white transition-all duration-300 hover:translate-x-0 hover:bg-[#4cc359]/80 ${
                   i18n.language === "ar" ? "translate-x-4" : "-translate-x-4"
               }`}
            ><FaWhatsapp/></a>
          </div>
      )}
    </>
  );
};

export default Share;
