import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import * as DOMPurify from "dompurify";
import {toggleScrolling} from "../../assets/js/helpers";
import ProgramCard from "../../components/program-card";
import {getUrlParamValue, PageCover, SideTab} from "../../components/global";
import {FaPlay} from "react-icons/fa";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation} from "swiper/modules";
import {HiArrowLongLeft, HiArrowLongRight} from "react-icons/hi2";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import YoutubeLightBox from "../../components/youtube-lightbox";
import {useGetProgramPageQuery} from "../../features/api/api-slice";
import ShareLinks from "../../components/share-links";
import {useParams} from "react-router-dom";
import TabTitle from "../../components/tab-title";
import SEO from "../../components/seo";

export default function ProgramsEntry() {
  const { t } = useTranslation("common");
  const slug = useParams().slug;
  const { data: programPageData } = useGetProgramPageQuery(slug);
  const program = programPageData?.data;
  const gallery = program?.gallery;
  const video = program?.video;
  const relatedPrograms = program?.related;
  const info = program && {
    title: program.title,
    link: window.location.href,
    description: program.description,
    image: program.coverImage,
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [slug]);

  return (
    <>
      {program && (
        <>
          <TabTitle title={program.title} />
          <SEO info={info} />
        </>
      )}
      <section className={`programs-entry`}>
        {program && (
          <>
            <PageCover title={program.title} image={program.coverImage} />

            <div className={"site-width"}>
              <div
                className={
                  "grid xs:grid-cols-1 sm:grid-cols-8 border-b border-gray-950"
                }
              >
                <div className={"p-10 col-span-5"}>
                  <div
                    className={`content-block`}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(program.content),
                    }}
                  />
                </div>
                <div className={"col-span-3"}>
                  <div>
                    <SideTab
                      title={t("programs.beneficiaries_number")}
                      content={
                        <span className={"text-2xl"}>
                          {program.beneficiariesNumber}
                        </span>
                      }
                    />
                    <SideTab
                      title={t("programs.sponsors")}
                      content={program.investor}
                    />
                    <SideTab
                      title={t("programs.governments")}
                      content={program.cities}
                    />
                  </div>
                  <div className="py-6 flex justify-center">
                    <ShareLinks title={program.title} />
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {gallery && <Gallery data={gallery} />}
        {video && video.link && <YoutubeVideo video={video.link} />}
        {relatedPrograms && (
          <OtherPrograms relatedProgramsInfo={relatedPrograms} />
        )}
      </section>
    </>
  );
}

function Gallery({ data }) {
  const slides = data.map((slide) => ({ src: slide }));
  const [index, setIndex] = useState(-1);
  const handleSlideClick = (index) => {
    setIndex(index);
  };
  return (
    <div>
      <div className={"programs-s-nav flex-it-[center-center] py-10 text-3xl"}>
        <div className={"w-[100px]"}>
          <div className={"s-next size-[30px] m-auto cursor-pointer"}>
            <HiArrowLongRight className={"m-auto"} />
          </div>
        </div>
        <div className={"w-[100px]"}>
          <div className={"s-prev size-[30px] m-auto cursor-pointer"}>
            <HiArrowLongLeft className={"m-auto"} />
          </div>
        </div>
      </div>
      <div className={"gallery py-16"}>
        <div className={"site-width"}>
          <Swiper
            navigation={{
              nextEl: ".programs-s-nav .s-next",
              prevEl: ".programs-s-nav .s-prev",
            }}
            modules={[Navigation]}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              540: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 4,
              },
            }}
            centerInsufficientSlides
          >
            {slides.map((slide, i) => {
              return (
                <SwiperSlide key={i} onClick={() => handleSlideClick(i)}>
                  <ProgramSlide slideData={slide.src} />
                </SwiperSlide>
              );
            })}
          </Swiper>
          <Lightbox
            open={index >= 0}
            slides={slides}
            close={() => setIndex(-1)}
            index={index}
          />
        </div>
      </div>
    </div>
  );
}

function ProgramSlide({ slideData }) {
  return (
    <div className="image">
      <img src={slideData} alt="" className="" />
    </div>
  );
}

function YoutubeVideo({ video }) {
  const [open, setOpen] = useState(false);
  const vParam = getUrlParamValue(video, "v");
  const ytCover = `https://img.youtube.com/vi/${vParam}/0.jpg`;
  const handleOpen = (value) => {
    setOpen(value);
    toggleScrolling(value);
  };
  const { t } = useTranslation("common");
  return (
    <div className={"program-youtube-video"}>
      <div className={"site-width flex xs:flex-col sm:flex-row gap-y-5"}>
        <div className="image xs:w-full sm:w-1/2 overflow-hidden">
          <img src={ytCover} className="h-full w-full object-cover" alt={''}/>
        </div>

        <div className={"flex xs:w-full sm:w-1/2"}>
          <div className={`flex-it-[center-start]`}>
            <div className={"px-10"}>
              <p className={`text-4xl font-bold mb-6`}>
                {t("programs.video_title")}
              </p>
              <div className={"flex-it-[center-start]"}>
                <button
                  className={`flex-it-[center-center] rounded-full bg-youtube size-[60px]`}
                  onClick={() => handleOpen(true)}
                >
                  <FaPlay
                    className={`text-white size-[20px] relative left-0.5`}
                  />
                </button>
                <span className={"px-5"}>{t("programs.video_button")}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <YoutubeLightBox open={open} handleOpen={handleOpen} src={video} />
      </div>
    </div>
  );
}

function OtherPrograms({ relatedProgramsInfo }) {
  const { t } = useTranslation("common");

  return (
    <div className={`py-16 bg-gray-950 border-t border-t-gray-800`}>
      <div className={"site-width"}>
        <p className={"text-2xl text-center font-bold mb-10"}>
          {t("programs.other_programs")}
        </p>
        <div
          className={
            "grid xs:grid-Fcols-1 sm:grid-cols-2 md:grid-cols-3  gap-8"
          }
        >
          {relatedProgramsInfo.map((program, i) => {
            return (
              <ProgramCard key={`program-other-${i}`} programInfo={program} />
            );
          })}
        </div>
      </div>
    </div>
  );
}
