import React from "react";
import {MapContainer, Marker, Popup, TileLayer} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import markerIcon from "../assets/images/marker-icon-red.png";
import {useTranslation} from "react-i18next";

const Map = ({ location }) => {
  const { t } = useTranslation("common");
  const customMarkerIcon = new L.Icon({
    iconUrl: markerIcon,
    iconSize: [40, 40],
  });
  const position = [location.lat, location.lng];
  return (
    <MapContainer
      center={position}
      zoom={13}
      scrollWheelZoom={true}
      className="w-full h-full"
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={customMarkerIcon}>
        <Popup>{t("app.site_name")}</Popup>
      </Marker>
    </MapContainer>
  );
};

export default Map;
