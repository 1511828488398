import React from "react";
import {useTranslation} from "react-i18next";
import MainSlider from "./main-slider";
import NewsEvents from "./news-events";
import PrimarySlider from "./primary-slider";
import Reports from "../../components/reports";
import SplitSections from "./split-sections";
import {useGetHomePageQuery} from "../../features/api/api-slice";
import CustomMarquee from "../../components/custom-marquee";
import TabTitle from "../../components/tab-title";
import SEO from "../../components/seo";

export default function Home({ scrollTag }) {
  const { t } = useTranslation("common");
  const { data: homePageDate } = useGetHomePageQuery();
  const latestNews = homePageDate?.data?.latestNews;
  const mainSlides = homePageDate?.data?.mainSlides;
  const programs = homePageDate?.data?.programs;
  const reports = homePageDate?.data?.reports;
  const reportsBackground = homePageDate?.data?.reportsBackground;
  const sections = homePageDate?.data?.sides;
  const featuredNews = homePageDate?.data?.featuredNews;
  const info = {
    title: t("app.site_name"),
    link: window.location.href,
    description: null,
    image: null,
  };

  console.log(homePageDate)
  return (
      <>
        <TabTitle />
        <SEO info={info} />
        <main className={``}>
          {latestNews &&
              <section className="contain-it-[100-1080] flex items-center">
                <label
                    className={
                      "bg-secondary text-white text-sm text-center w-52 whitespace-nowrap p-2"
                    }
                >
                  {t("marquee.latest_news")}
                </label>
                <CustomMarquee componentsList={featuredNews} />
              </section>
          }
          <section className="">
            {mainSlides && <MainSlider slidesInfo={mainSlides} />}
          </section>
          <section className="py-10">
            {programs && <PrimarySlider slidesInfo={programs} />}
          </section>
          <section className="xs:w-[90%] md:w-[60%] mx-auto py-10">
            {reports && <Reports reportsInfo={reports} reportsBackground={reportsBackground} />}
          </section>
          <section className="py-10">
            {sections && <SplitSections sectionsInfo={sections} />}
          </section>
          <section className="py-10">
            {latestNews && <NewsEvents featuredNewsInfo={latestNews} />}
          </section>
        </main>
      </>
  );
}
