import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";

const NewsCard = ({ singleNewsInfo }) => {
  return (
    <CardHovered className="flex-1">
      <Link reloadDocument={true}
        className="group block transition-all duration-300"
        to={`/news/${singleNewsInfo.slug}`}
      >
        <div className="image relative after:transition-all after:duration-300 after:opacity-0 group-hover:after:opacity-20 after:bg-black after:absolute after:w-full after:h-full after:top-0 after:left-0">
          <img
            src={singleNewsInfo.image || singleNewsInfo.coverImage}
            alt=""
            className="h-56 w-full object-cover"
          />
        </div>
        <div className="text px-3 pt-4">
          <h4 className="leading-7 text-xl">{singleNewsInfo.title}</h4>
          <p className="text-gray-500 pt-4">{singleNewsInfo.summary}</p>
        </div>
      </Link>
    </CardHovered>
  );
};

const CardHovered = styled.div`
  a:hover h4 {
    display: inline;
    background: #921831;
    padding: -1px;
    box-shadow: 0.57143rem 0 0 #921831, -0.57143rem 0 0 #921831;
    color: white;
    transition: all 0.3s ease;
  }
`;

export default NewsCard;
