import React, {useEffect, useRef} from "react";
import {PageCover} from "../../components/global";
import ProgramCard from "../../components/program-card";
import {useGetProgramsPageQuery} from "../../features/api/api-slice";
import TabTitle from "../../components/tab-title";
import {useParams} from "react-router-dom";
import SEO from "../../components/seo";

export default function ProgramsIndex() {
  const { data: programsPageData } = useGetProgramsPageQuery();
  const pageInfo = programsPageData?.data?.pageInfo;
  const programsCats = programsPageData?.data?.items;
  const programsSectionRef = useRef(null);
  const params = useParams();
  const info = pageInfo && {
    title: pageInfo.title,
    link: window.location.href,
    description: pageInfo.description,
    image: pageInfo.coverImage,
  };

  useEffect(() => {
    const sectionElement = document.getElementById(
      window.location.hash.split("#")[1]
    );
    sectionElement && sectionElement.scrollIntoView({ behavior: "smooth" });
  }, [params]);

  return (
    <>
      {pageInfo && (
        <>
          <TabTitle title={pageInfo.title} />
          <SEO info={info} />
        </>
      )}
      <section className={"programs-index"}>
        {pageInfo && (
          <PageCover title={pageInfo.title} image={pageInfo.coverImage} />
        )}
        <div className={"site-width"}>
          {pageInfo && <p className={"snippet"}>{pageInfo.description}</p>}

          {programsCats &&
            programsCats.map((cat, i) => {
              return (
                <div
                  className={"program-container"}
                  key={`programs-list-${i}`}
                  id={`program${cat.id}`}
                  ref={programsSectionRef}
                >
                  <p id={cat.slug} className={`ribbon`}>
                    {cat.title}
                  </p>
                  <p className={`s`}>{cat.snippet}</p>
                  <div
                    className={
                      "grid xs:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-5"
                    }
                  >
                    {cat.programs.map((program, index) => {
                      return <ProgramCard programInfo={program} key={index} />;
                    })}
                  </div>
                </div>
              );
            })}
        </div>
      </section>
    </>
  );
}
