import React from "react";
import {Link} from "react-router-dom";

export default function Error404() {
  return (
    <section className={``}>
      <div className="flex justify-center items-center h-screen flex-col gap-4">
        <p className="text-4xl">الصفحة غير موجودة</p>
        <Link reloadDocument={true} to="/">العودة إلى الرئيسية</Link>
      </div>
    </section>
  );
}
