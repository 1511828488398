import React from "react";
import {useTranslation} from "react-i18next";
import NewsCard from "../../components/news-card";
import {useGetNewsPageQuery} from "../../features/api/api-slice";
import TabTitle from "../../components/tab-title";

export default function NewsIndex() {
  const { t } = useTranslation("common");
  const { data: newsPageData } = useGetNewsPageQuery();
  const newsList = newsPageData?.data.items;

  return (
    <>
      {<TabTitle title={t("header.news")} />}
      <main className={``}>
        <section className="contain-it-[95-1080] py-10">
          <div className="header flex justify-between pb-10">
            <div className="title text-lg">
              <span>{t("header.news")}</span>
            </div>
          </div>
          <div className="news-list grid xs:grid-rows-1 md:grid-cols-2 gap-x-4 gap-y-20">
            {newsList &&
              newsList.map((news, index) => (
                <NewsCard key={index} singleNewsInfo={news} />
              ))}
          </div>
        </section>
      </main>
    </>
  );
}
