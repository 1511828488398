import React from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectFade, Navigation, Pagination} from "swiper/modules";
import {useTranslation} from "react-i18next";

const MainSlider = ({ slidesInfo }) => {
    return (
        <div className={'main-slider'}>
            <Swiper
                // effect={"fade"}
                // fadeEffect={{
                //     crossFade: true
                // }}
                centeredSlides={true}
                loop={true}
                autoplay={{
                    delay: 4000,
                    disableOnInteraction: false,
                    stopOnLastSlide: false,
                    pauseOnMouseEnter: false
                }}
                navigation={true}
                pagination={{
                    el: ".main-slider .pagination",
                    clickable: true
                }}
                speed={1000}
                modules={[Autoplay, EffectFade, Navigation, Pagination]}
            >
                {slidesInfo.map((slideInfo, index) => {
                    return (
                        <SwiperSlide key={index}>
                            <SingleSlide slideInfo={slideInfo} />
                        </SwiperSlide>
                    );
                })}
            </Swiper>

            <div className={'pagination'}/>
        </div>
    );
};

const SingleSlide = ({ slideInfo }) => {
    const { t } = useTranslation("common");
    return (
        <div className="">
            <CustomSlide className="min-h-[350px] md:min-h-[540px] bg-no-repeat bg-cover flex relative" imageUrl={slideInfo.image}>
                <div className="text py-4 text-white text-3xl w-full absolute right-0 h-full flex items-end">
                    <div className={"contain-it-[95-1080] pb-8"}>
                        {slideInfo.title &&
                            <>
                                <p className="text-4xl pb-5">{slideInfo.title}</p>
                                <a href={slideInfo.link}
                                   className="text-white text-[17px] text-center px-4 py-2 bg-secondary w-[130px] block
                                   max-md:text-[14px] max-md:w-[90px] max-md:px-1.5 max-md:py-0.5">{t("home.show_more")}</a>
                            </>
                        }
                    </div>
                </div>
            </CustomSlide>
        </div>
    );
};

const CustomSlide = styled.div`
    background-image: url(${(props) => props.imageUrl});
`;

export default MainSlider;
