import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {NavLink, useNavigate} from "react-router-dom";
import {FaCaretDown, FaSearch} from "react-icons/fa";
import {BiMenu, BiX} from "react-icons/bi";
import {Field, Form, Formik} from "formik";
import {HashLink} from "react-router-hash-link";

export default function Header({ headerInfo }) {
  const { t } = useTranslation("common");
  const [searchOpen, setSearchOpen] = useState(false);
  const toggleSearch = () => {
    setSearchOpen((prevState) => !prevState);
  };
  return (
    <header className="relative">
      <div className="contain-it-[95-1080] grid grid-cols-12 gap-3 py-4">
        <div className="col-span-6">
          <NavLink reloadDocument={true} to={""} className={'max-w-[230px] block'}>
            <img
              src="/assets/images/logo.png?v=1"
              alt=""
              className={`w-[230px] max-md:w-[150px]`}
            />
          </NavLink>
        </div>
        <div className="col-span-6 flex-it-[center-end]">
          <div className={"block max-md:hidden"}>
            <ChangeLanguage />
          </div>
          <div className={"block max-md:hidden"}>
            <NavLink reloadDocument={true} to={`donate`} className={"default-btn"}>
              {t("header.donate")}
            </NavLink>
          </div>
          <div className={"hidden max-md:block"}>
            <PhoneMenu headerInfo={headerInfo} />
          </div>
        </div>
      </div>

      <div className={"bg-primary max-md:hidden relative"}>
        <div className="contain-it-[95-1080] grid grid-cols-12 gap-3">
          <div className={"col-span-11"}>
            <HeaderNav headerInfo={headerInfo} />
          </div>
          <div className={"col-span-1 flex-it-[center-end]"}>
            <button
              className={`text-white text-center h-full px-4 ${
                searchOpen ? "bg-white" : ""
              }`}
              onClick={toggleSearch}
            >
              {searchOpen ? <CloseButton /> : <FaSearch />}
            </button>
          </div>
        </div>
        {searchOpen && (
          <div className="search-input absolute top-full left-1/2 -translate-x-1/2 z-10 bg-white w-full pb-3">
            <div className="contain-it-[95-1080]">
              <SearchForm />
            </div>
          </div>
        )}
      </div>
    </header>
  );
}

function ChangeLanguage() {
  const { i18n } = useTranslation("common");
  return (
    <a
      href={`${i18n.language === "en" ? "/" : "/en"}`}
      className={"text-primary flex-it-[center-end] mx-5 max-md:mx-0"}
    >
      <img
        className="w-[18px] mx-2"
        src={`/assets/images/${i18n.language === "en" ? "sa" : "gb"}.png`}
        alt={""}
      />
      <span className={"pt-[5px]"}>
        {i18n.language === "en" ? "عربي" : "English"}
      </span>
    </a>
  );
}

function PhoneMenu({ headerInfo }) {
  const { t, i18n } = useTranslation("common");
  const [openMenu, setOpenMenu] = useState(false);
  return (
    <>
      <button
        className={"size-[50px] flex-it-[center-end] text-[26px]"}
        onClick={() => {
          setOpenMenu(true);
        }}
      >
        <BiMenu className={``} />
      </button>
      <div className={`phone-menu ${openMenu ? "active" : ""}`}>
        <div
          className={`items-holder ${
            i18n.language === "en"
              ? "right-0 translate-x-[100%]"
              : "left-0 -translate-x-[100%]"
          }`}
        >
          <button
            className={"size-[50px] flex-it-[center-start] mx-2 text-[26px]"}
            onClick={() => {
              setOpenMenu(false);
            }}
          >
            <BiX />
          </button>
          <HeaderNav target={"phone"} headerInfo={headerInfo} />
          <div className={"p-4"}>
            <ChangeLanguage />
          </div>
          <div className={"px-4"}>
            <NavLink reloadDocument={true}
              to={`donate`}
              className={"default-btn max-md:px-4 max-md:w-full max-md:block"}
            >
              {t("header.donate")}
            </NavLink>
          </div>
        </div>
        <div
          className={"over"}
          onClick={() => {
            setOpenMenu(false);
          }}
        />
      </div>
    </>
  );
}

function HeaderNav({ target = "", headerInfo }) {
  const { t } = useTranslation("common");
  const [dropDown, SetOpenDropDown] = useState(false);
  const handleDropDown = (val) => {
    SetOpenDropDown(val);
  };
  const closeDropDownMenu = () => {
    SetOpenDropDown(false);
  };
  return (
    <nav className={`header-nav ${target === "phone" ? "flex-col" : ""}`}
         onMouseLeave={() => {
           handleDropDown(false)
         }}>
      <NavLink reloadDocument={true} className={"item py-5"} to={"/"} onClick={closeDropDownMenu}>
        {t("header.home")}
      </NavLink>
      <NavLink reloadDocument={true} className={"item py-5"} to={"/about"} onClick={closeDropDownMenu}>
        {t("header.about")}
      </NavLink>
      <NavLink reloadDocument={true} className={"item py-5"} to={"/kafala"} onClick={closeDropDownMenu}>
        {t("header.kafala")}
      </NavLink>
      <div className={"max-md:w-full"}>
        <div
          className={"item relative py-5 z-10 cursor-pointer"}
          onMouseEnter={() => {
            handleDropDown(true)
          }}
        >
          <div className={"flex-it-[center-start] gap-2"}>
            <NavLink to="/programs">{t("header.programs")}</NavLink>
            <span>
              <FaCaretDown />
            </span>
          </div>
          <div
            className={`md:absolute top-full md:bg-primary rounded md:rounded-none bg-gray-950 mt-2 md:mt-0 ${
              dropDown ? "block" : "hidden"
            }`}
          >
            {headerInfo?.programs &&
              headerInfo.programs.map((item, i) => {
                return (
                  <HashLink
                    className={"item py-2  w-72 last:border-none hover:bg-[#46668e]"}
                    // to={`/programs#${item.slug}`}
                    to={`/programs#${item.slug}`}
                    key={`header-programs-${i}`}
                    onClick={closeDropDownMenu}
                  >
                    {item.title}
                  </HashLink>
                );
              })}
          </div>
        </div>
      </div>
      <NavLink reloadDocument={true} className={"item py-5"} to={"/news"} onClick={closeDropDownMenu}>
        {t("header.news")}
      </NavLink>
      <NavLink reloadDocument={true} className={"item py-5"} to={"/media"} onClick={closeDropDownMenu}>
        {t("header.media")}
      </NavLink>
        <NavLink reloadDocument={true} className={"item py-5"} to={"/contact"} onClick={closeDropDownMenu}>
        {t("header.contact")}
      </NavLink>
    </nav>
  );
}

const SearchForm = () => {
  const { i18n } = useTranslation("common");
  const navigate = useNavigate();
  return (
    <Formik
      initialValues={{ search: "" }}
      onSubmit={(values) => {
        navigate(`/search/${values.search}`);
      }}
    >
      <Form>
        <div className={`relative flex py-3 border-b border-gray-650`}>
          <Field
            type="text"
            name="search"
            className={`outline-none font-bold flex-auto text-xl ${
              i18n.language === "en" ? "prl-9" : "pl-9"
            }`}
          />
          <button
            type="submit"
            className={`absolute top-1/2 -translate-y-1/2 p-3 ${
              i18n.language === "en" ? "right-0" : "left-0"
            }`}
          >
            <FaSearch />
          </button>
        </div>
      </Form>
    </Formik>
  );
};

const CloseButton = () => {
  return (
    <div className="text-black">
      <span className="rotate-45 text-3xl font-bold inline-block">+</span>
    </div>
  );
};
