import React, {Fragment} from "react";
import {useTranslation} from "react-i18next";
import {NavLink} from "react-router-dom";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter, FaWhatsapp, FaYoutube,} from "react-icons/fa";
import {Field, Form, Formik} from "formik";
import {useSubscribeNewsletterMutation} from "../features/api/api-slice";

export default function Footer({ footerInfo, socialInfo }) {
    const { t } = useTranslation("common");
    console.log(footerInfo)
    return (
        <footer className={`footer`}>
            <div className={"xs:w-[80%] md:w-[60%] mx-auto grid grid-cols-12 gap-8"}>
                <div className={"col-span-8 grid grid-cols-12 gap-4 max-md:col-span-12"}>
                    {footerInfo.slice(0, 1).map((list, index) => (
                        <Fragment key={index}>
                            <div className={"xs:col-span-12 sm:col-span-6"}>
                                <p className={"title"}>{list.title}</p>
                                <div className={"links grid grid-cols-2 gap-2 flex-col"}>
                                    {list.links.map((item, i) => {
                                        return (
                                            <a href={item.slug}
                                                key={`footer-links-${i}`}
                                                className={"item"}
                                            >
                                                <span>{item.title}</span>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </Fragment>
                    ))}
                    {footerInfo.slice(1, 2).map((list, index) => (
                        <Fragment key={index}>
                            <div className={"xs:col-span-12 sm:col-span-6"}>
                                <p className={"title"}>{list.title}</p>
                                <div className={"links flex gap-2 flex-col"}>
                                    {list.links.map((item, i) => {
                                        return (
                                            <a href={`/programs#${item.slug}`}
                                                key={`footer-links-${i}`}
                                                className={"item"}
                                            >
                                                <span>{item.title}</span>
                                            </a>
                                        );
                                    })}
                                </div>
                            </div>
                        </Fragment>
                    ))}
                </div>
                <div className={'col-span-4 max-md:col-span-12 newsletter'}>
                    <NewsLetter />
                </div>
            </div>
            <div className={"xs:w-[80%] md:w-[60%] mx-auto flex flex-col gap-3 pt-10"}>
                <div>
                    <div className="social">
                        <a href={socialInfo.facebook} target="_blank" rel="noreferrer"><FaFacebookF /></a>
                        <a href={socialInfo.twitter} target="_blank" rel="noreferrer"><FaTwitter /></a>
                        <a href={socialInfo.youtube} target="_blank" rel="noreferrer"><FaYoutube /></a>
                        <a href={socialInfo.linkedin} target="_blank" rel="noreferrer"><FaLinkedinIn /></a>
                        <a href={socialInfo.instagram} target="_blank" rel="noreferrer"><FaInstagram /></a>
                        <a href={socialInfo.whatsapp} target="_blank" rel="noreferrer"><FaWhatsapp /></a>
                    </div>
                </div>
                <div>
                    <p className={"text-white text-xs text-opacity-80"}>© {new Date().getFullYear()}{t("footer.rights")}{t("footer.developed_by")}{" "}
                        <a href="https://onegr.com" target="_blank" rel="noreferrer" className={"text-[#f47929]"}>{t("footer.developer")}</a>
                    </p>
                </div>
            </div>
        </footer>
    );
}

const NewsLetter = () => {
    const { t } = useTranslation("common");
    const [subscribeNewsletter, { isSuccess, isLoading, isError }] =
        useSubscribeNewsletterMutation();
    return (
        <>
            <p className={"t"}>{t("footer.newsletter_title")}</p>
            <Formik
                initialValues={{
                    email: "",
                }}
                onSubmit={async (values) => {
                    await subscribeNewsletter(values);
                    alert(
                        isSuccess
                            ? t("footer.newsletter_success")
                            : t("footer.newsletter_failed")
                    );
                }}
            >
                <Form method={"post"}>
                    <Field
                        type="email"
                        name="email"
                        placeholder={t("footer.email_address")}
                        required
                    />
                    <button type="submit">{t("footer.newsletter_signup")}</button>
                </Form>
            </Formik>
        </>
    );
};
