import React from "react";
import CustomTabs from "../../components/custom-tabs";

const Values = ({ valuesInfo }) => {
  const tabsInfo = valuesInfo.map((value) => {
    return {
      id: value.id,
      tab: value.title,
      TabPanel: value.description,
    };
  });

  return (
    <div className="contain-it-[95-1080] flex justify-center">
      <CustomTabs tabsInfo={tabsInfo} />
    </div>
  );
};

export default Values;
