import {Link} from "react-router-dom";
import styled from "styled-components";

const ProgramCard = ({ programInfo }) => {
  return (
    <Link reloadDocument={true}
      to={`/programs/${programInfo.slug}`}
      className="group block transition-all duration-500 hover:bg-secondary hover:text-white"
    >
      <div className="image overflow-hidden relative after:transition-all after:duration-500 after:opacity-0 group-hover:after:opacity-100 after:content-[''] after:h-full after:w-full after:bg-gradient-to-t group-hover:after:from-black after:absolute after:top-0 after:left-0">
        <CustomImage
          src={programInfo.coverImage}
          alt=""
          className="block hover:text-white object-cover w-full"
        />
      </div>
      <div className="text px-2 py-3">
        <p className="text-center text-lg font-bold">{programInfo.title}</p>
      </div>
    </Link>
  );
};

const CustomImage = styled.img`
  -webkit-transform: rotateX(-2deg);
  -webkit-transform-origin: left;
  transition: all 3s;
  &:hover {
    transform: rotateX(0deg);
  }
`;

export default ProgramCard;
