import React from "react";
import {useTranslation} from "react-i18next";
import {Helmet} from "react-helmet-async";

const TabTitle = ({ title }) => {
  const { t } = useTranslation("common");
  return (
    <Helmet>
      <title>{`${t("app.site_name")} ${title ? " | " + title : ""}`}</title>
    </Helmet>
  );
};

export default TabTitle;
