import React from "react";
import {IoIosClose} from "react-icons/io";
import {getUrlParamValue} from "./global";

const YoutubeLightBox = ({ open, handleOpen, src }) => {
  return (
    <div className="">
      <div
        className={`video-wrapper fixed top-0 left-0 bottom-0 right-0 z-30 ${
          open ? "block" : "hidden"
        }`}
      >
        <div
          className={`overlay flex flex-col h-full w-full bg-black/75 absolute left-0 top-0 right-0 bottom-0`}
          onClick={() => handleOpen(false)}
        >
          <div className="close max-w-4xl px-3">
            <button
              className="text-4xl text-white p-1"
              onClick={() => handleOpen(false)}
            >
              <IoIosClose className="" />
            </button>
          </div>
          <div className="video max-w-4xl xs:h-1/3 md:h-2/3 lg:h-11/12 px-4  m-auto grid items-center w-full">
            <YoutubeIframe src={src} />
          </div>
        </div>
      </div>
    </div>
  );
};

const YoutubeIframe = ({ src }) => {
  const videoParam = getUrlParamValue(src, "v");
  return (
    <iframe
      width="100%"
      height="100%"
      src={`https://www.youtube.com/embed/${videoParam}`}
      title="YouTube video player"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      allowFullScreen
    ></iframe>
  );
};

export default YoutubeLightBox;
