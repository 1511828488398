import React from "react";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";

const Stats = ({ statsInfo }) => {
    const { t } = useTranslation("common");

    function batch (arr) {
        const batches = []
        arr = [].concat(...arr)
        while (arr.length) {
            batches.push(arr.splice(0, 4))
        }
        return batches
    }
    const arr = batch(statsInfo);
    return (
        <div>
            <div className="title pb-10">
                <h2 className="text-2xl text-center">{t("stats.stats")}</h2>
            </div>
            <div className="stats grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 justify-center">
                {arr.map((i1, index2) => {
                    return (
                        <>
                            {i1.map((stat, index) => {
                                return (
                                    <Stat
                                        key={stat.id}
                                        isLast={index === i1.length-1}
                                        statInfo={stat}
                                    />
                                );
                            })}
                        </>
                    );
                })}
            </div>
            <div className="flex justify-center pt-10">
                <Link reloadDocument={true} className="default-btn" to="/programs">
                    {t("stats.show_all_projects")}
                </Link>
            </div>
        </div>
    );
};

const Stat = ({ statInfo, isLast }) => {
    return (
        <div className="flex col-span-1 sm:col-span-1 md:col-span-1 justify-center">
            <div className="text-secondary flex flex-col text-center justify-center py-10 px-10 w-full">
                <div className="figure text-3xl  font-bold">
                    <span>{statInfo.description}</span>
                </div>
                <div className="figure-name">
                    <span>{statInfo.title}</span>
                </div>
            </div>
            <div className={`${isLast ? "md:hidden" : "md:py-8 md:block"} sm:hidden hidden`}>
                <span className="block border-l border-gray-850 h-[50px] w-full rotate-[20deg]"></span>
            </div>
        </div>
    );
};

export default Stats;
