import React from "react";
import {useTranslation} from "react-i18next";

export default function ReliefEntry() {
    const {t} = useTranslation('common');

    return (
        <section className={``}>

        </section>
    );
}










