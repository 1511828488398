import React from "react";
import {Link} from "react-router-dom";
import styled from "styled-components";
import {ImFilePdf} from "react-icons/im";
import {useTranslation} from "react-i18next";
import {HashLink} from "react-router-hash-link";

const Reports = ({ reportsInfo, reportsBackground }) => {
  const [t] = useTranslation("common");
  return (
    <div className="relative">
      <div className={`content flex flex-col justify-center items-center rounded-md bg-center bg-cover pt-16 p-8`}
      style={{
        backgroundImage: `url('${reportsBackground}')`
      }}
      >
        <div className="text-center pb-3 text-white">
          <h4 className="text-2xl">{t("reports.heading")}</h4>
          <p className="text-lg">{t("reports.paragraph")}</p>
        </div>
        <div className="all-reports pt-8">
          <HashLink to={"/media#reports-container"} className="inline-block bg-red-800 rounded px-6 py-2 text-white">{t("reports.all_reports")}</HashLink>
        </div>
        <div className=" pt-8">
          <ul className="grid grid-cols-3 gap-x-8 gap-y-4 justify-around flex-wrap items-center">
            {reportsInfo.map((report, index) => (
              <li className={`bg-white col-span-3 lg:col-span-1`} key={index}>
                <SingleReportLink reportInfo={report} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

const SingleReportLink = ({ reportInfo }) => {
  return (
    <a
      target="_blank" rel="noreferrer"
      href={reportInfo.file}
      className="group flex gap-x-3 items-center py-2 px-4 transition-all duration-200 hover:bg-secondary hover:text-white"
      title={reportInfo.title}
    >
      <span>
        <ImFilePdf className="text-[#d4c7a0] group-hover:text-white" />
      </span>
      <span className="leading-7 truncate">{reportInfo.title}</span>
    </a>
  );
};
export default Reports;
