import React from "react";
import FostersList from "./fosters-list";
import Hero from "./hero";
import {useGetKafalaPageQuery} from "../../features/api/api-slice";
import TabTitle from "../../components/tab-title";
import SEO from "../../components/seo";

export default function Kafala() {
  const { data: kafalaPageData } = useGetKafalaPageQuery();
  const fosters = kafalaPageData?.data?.items;
  const pageInfo = kafalaPageData?.data?.pageInfo;
  const fostersTypes = kafalaPageData?.data?.types;
  const fostersFiltersTypes = fostersTypes && [
    { id: 0, fosterType: "all", text: "الكل" },
    ...fostersTypes.map((type) => ({
      id: type.id,
      fosterType: type.title.split(" ").join("-"),
      text: type.title,
    })),
  ];

  const info = pageInfo && {
    title: pageInfo.title,
    description: pageInfo.description,
    link: window.location.href,
    image: pageInfo.coverImage,
  };

  return (
    <>
      {pageInfo && (
        <>
          <TabTitle title={pageInfo.title} />
          <SEO info={info} />
        </>
      )}
      <main className={``}>
        <section>{pageInfo && <Hero heroInfo={pageInfo} />}</section>
        <section className="py-10">
          {fosters && (
            <FostersList
              fostersInfo={fosters}
              fostersFiltersTypesInfo={fostersFiltersTypes}
            />
          )}
        </section>
      </main>
    </>
  );
}
