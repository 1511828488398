import React from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";

const SplitSections = ({ sectionsInfo }) => {
  return (
    <div className="xs:w-[80%] md:w-[60%] mx-auto">
      {sectionsInfo.map((section, index) => {
        return (
          <SingleSplitSection
            sectionInfo={section}
            isReversed={index % 2 === 0}
            key={index}
          />
        );
      })}
    </div>
  );
};

const SingleSplitSection = ({ isReversed, sectionInfo }) => {
  const [t] = useTranslation("common");
  return (
    <Link reloadDocument={true}
      to={
        sectionInfo.slug === "about"
          ? sectionInfo.slug
          : `/programs/${sectionInfo.slug}`
      }
      className={`flex items-center xs:py-10 lg:py-0 ${
        isReversed
          ? "xs:flex-col lg:flex-row-reverse"
          : "xs:flex-col lg:flex-row"
      }`}
    >
      <div className="image h-60 w-full">
        <img
          src={sectionInfo.image}
          alt=""
          className="h-full w-full object-cover"
        />
      </div>
      <div className="text px-2 py-5 text-center w-full">
        <h3 className="text-4xl font-light pb-4">{sectionInfo.title}</h3>
        <div className="w-2/3 mx-auto">
          <div
            className="pb-9 last:pb-0"
            dangerouslySetInnerHTML={{ __html: sectionInfo.description }}
          ></div>
        </div>
        <span className="inline-block pt-4 border-b-2 transition-all duration-200 hover:text-secondary">
          {t("home.show_more")}
        </span>
      </div>
    </Link>
  );
};

export default SplitSections;
