import React from "react";
import {BsFacebook, BsTwitter, BsWhatsapp} from "react-icons/bs";
import {useGetArticlePageQuery} from "../../features/api/api-slice";
import ShareLink from "../../components/share-links";
import {useParams} from "react-router-dom";
import TabTitle from "../../components/tab-title";
import SEO from "../../components/seo";

const Article = () => {
  const slug = useParams().slug;
  const { data: articlePageData } = useGetArticlePageQuery(slug);
  const article = articlePageData?.data;
  const info = article && {
    title: article.title,
    link: window.location.href,
    description: article.description,
    image: article.coverImage,
  };

  return (
    <>
      {article && (
        <>
          <TabTitle title={article.title} />
          <SEO info={info} />
        </>
      )}
      <main className={``}>
        {article && (
          <section className="contain-it-[95-1080] text-center">
            <div className="header">
              <div className="image">
                <img src={article.coverImage} alt="" />
              </div>
              <div className="info py-10">
                <div className="title">
                  <h1 className="text-lg pb-3">{article.title}</h1>
                </div>
                <div className="date pb-3">
                  <span className="date text-base text-gray-350">
                    {article.postDate}
                  </span>
                </div>
                <div className="share flex gap-x-2 text-gray-250 justify-center">
                  <ShareLink title={article.title} />
                </div>
              </div>
            </div>
            <div className="body xs:w-[80%] md:w-[60%] mx-auto text-gray-300 pb-10">
              <div
                className="pb-6 last:pb-0"
                dangerouslySetInnerHTML={{ __html: article.content }}
              ></div>
            </div>
          </section>
        )}
      </main>
    </>
  );
};

const ShareArticle = () => {
  return (
    <>
      <a href="" className="hover:text-gray-50 transition-all duration-300">
        <BsFacebook />
      </a>
      <a href="" className="hover:text-gray-50 transition-all duration-300">
        <BsTwitter />
      </a>
      <a href="" className="hover:text-gray-50 transition-all duration-300">
        <BsWhatsapp />
      </a>
    </>
  );
};

export default Article;
